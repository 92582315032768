import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'AdminFormMixin'
})
export default class AdminFormMixin extends Vue {
  schema = [
    {
      key: 'name',
      rules: [{
        required: true,
        message: 'Name is required',
        trigger: 'blur'
      }]
    },
    {
      key: 'username',
      label: 'Email',
      rules: [{
        required: true,
        message: 'Email is required',
        trigger: 'blur'
      }, {
        type: 'email',
        message: 'Please enter a valid email address',
        trigger: 'blur'
      }]
    },
    {
      key: 'role',
      type: 'select',
      items: this.$store.getters['user/roles'],
      rules: [{
        required: true,
        message: 'Role is required',
        trigger: 'blur'
      }]
    },
    {
      key: 'client.id',
      label: 'Client Access',
      type: 'select',
      placeholder: 'Select client',
      // optionKey: 'id',
      // multiple: true,
      // multipleLimit: 1,
      items: this.$store.getters['client/getList']('dropdown'),
      visibleIf (model) {
        if (model && model.role === 'ADMIN') {
          return false;
        }
        return true;
      },
      rules: [{
        required: true,
        message: 'Client is required',
        trigger: 'blur'
      }]
    }
  ]
}
