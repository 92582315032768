import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ClientFormMixin'
})
export default class ClientFormMixin extends Vue {
  industries = [
    // Charity
    { id: 'Charity', name: 'Charity', sector: 'Charity' },
    // Retail Goods & Services
    { id: 'Arts & Entertainment', name: 'Arts & Entertainment', sector: 'Retail Goods & Services' },
    { id: 'Automobiles & Parts', name: 'Automobiles & Parts', sector: 'Retail Goods & Services' },
    { id: 'Fashion, Clothing & Footwear', name: 'Fashion, Clothing & Footwear', sector: 'Retail Goods & Services' },
    { id: 'Health & Beauty', name: 'Health & Beauty', sector: 'Retail Goods & Services' },
    { id: 'Hotels & Restaurants', name: 'Hotels & Restaurants', sector: 'Retail Goods & Services' },
    { id: 'Sport & Leisure', name: 'Sport & Leisure', sector: 'Retail Goods & Services' },
    { id: 'Technology', name: 'Technology', sector: 'Retail Goods & Services' },
    { id: 'Wholesale & Logistics ', name: 'Wholesale & Logistics ', sector: 'Retail Goods & Services' },
    { id: 'Work, Home & Lifestyle', name: 'Work, Home & Lifestyle', sector: 'Retail Goods & Services' },
    // Energy, Resources & Materials
    { id: 'Agriculture', name: 'Agriculture', sector: 'Energy, Resources & Materials' },
    { id: 'Chemicals', name: 'Chemicals', sector: 'Energy, Resources & Materials' },
    { id: 'Electric Utilities', name: 'Electric Utilities', sector: 'Energy, Resources & Materials' },
    { id: 'Energy Equipment, Services & Distribution', name: 'Energy Equipment, Services & Distribution', sector: 'Energy, Resources & Materials' },
    { id: 'Forestry & Paper', name: 'Forestry & Paper', sector: 'Energy, Resources & Materials' },
    { id: 'Gas Utilities ', name: 'Gas Utilities', sector: 'Energy, Resources & Materials' },
    { id: 'Independent and Renewable Producers', name: 'Independent and Renewable Producers', sector: 'Energy, Resources & Materials' },
    { id: 'Metals & Mining', name: 'Metals & Mining', sector: 'Energy, Resources & Materials' },
    { id: 'Multi-Utilities ', name: 'Multi-Utilities ', sector: 'Energy, Resources & Materials' },
    { id: 'Water Utilities', name: 'Water Utilities', sector: 'Energy, Resources & Materials' },
    // Financials
    { id: 'Banking', name: 'Banking', sector: 'Financials' },
    { id: 'Financial Services', name: 'Financial Services', sector: 'Financials' },
    { id: 'Insurance', name: 'Insurance', sector: 'Financials' },
    { id: 'Investment Banking ', name: 'Investment Banking', sector: 'Financials' },
    { id: 'Real Estate', name: 'Real Estate', sector: 'Financials' },
    // Food & Drink
    { id: 'Drink Producers', name: 'Drink Producers', sector: 'Food & Drink' },
    { id: 'Food Producers', name: 'Food Producers', sector: 'Food & Drink' },
    { id: 'Supermarkets', name: 'Supermarkets', sector: 'Food & Drink' },
    // Public Sector
    { id: 'Defence', name: 'Defence', sector: 'Public Sector' },
    { id: 'Education', name: 'Education', sector: 'Public Sector' },
    { id: 'Health', name: 'Health', sector: 'Public Sector' },
    // Health Care
    { id: 'Health Care Equipment & Supplies', name: 'Health Care Equipment & Supplies', sector: 'Health Care' },
    { id: 'Health Care Providers & Services ', name: 'Health Care Providers & Services', sector: 'Health Care' },
    { id: 'Pharmaceuticals & Biotechnology', name: 'Pharmaceuticals & Biotechnology', sector: 'Health Care' },
    // Industrial & Manufacturing
    { id: 'Aerospace & Defence', name: 'Aerospace & Defence', sector: 'Industrial & Manufacturing' },
    { id: 'Construction & Materials', name: 'Construction & Materials', sector: 'Industrial & Manufacturing' },
    { id: 'Industrial Goods and Support Services', name: 'Industrial Goods and Support Services', sector: 'Industrial & Manufacturing' },
    { id: 'Transportation', name: 'Transportation', sector: 'Industrial & Manufacturing' },
    // Professional Services
    { id: 'Accountancy', name: 'Accountancy', sector: 'Professional Services' },
    { id: 'Consultancy', name: 'Consultancy', sector: 'Professional Services' },
    { id: 'Legal', name: 'Legal', sector: 'Professional Services' },
    { id: 'Other (e.g. Business Admin, Recruitment)', name: 'Other (e.g. Business Admin, Recruitment)', sector: 'Professional Services' },
    { id: 'Scientific', name: 'Scientific', sector: 'Professional Services' },
    // Technology, Media & Telecoms
    { id: 'Gaming', name: 'Gaming', sector: 'Technology, Media & Telecoms' },
    { id: 'Interactive Media', name: 'Interactive Media', sector: 'Technology, Media & Telecoms' },
    { id: 'Publishing', name: 'Publishing', sector: 'Technology, Media & Telecoms' },
    { id: 'Radio, Music & Audio', name: 'Radio, Music & Audio', sector: 'Technology, Media & Telecoms' },
    { id: 'Communications & Electronic Equipment', name: 'Communications & Electronic Equipment', sector: 'Technology, Media & Telecoms' },
    { id: 'IT Services', name: 'IT Services', sector: 'Technology, Media & Telecoms' },
    { id: 'Software', name: 'Software', sector: 'Technology, Media & Telecoms' },
    { id: 'Hardware, Storage & Peripherals ', name: 'Hardware, Storage & Peripherals', sector: 'Technology, Media & Telecoms' },
    { id: 'Fixed Line Telecoms', name: 'Fixed Line Telecoms', sector: 'Technology, Media & Telecoms' },
    { id: 'Mobile Telecoms', name: 'Mobile Telecoms', sector: 'Technology, Media & Telecoms' },
    { id: 'TV & Film', name: 'TV & Film', sector: 'Technology, Media & Telecoms' }
  ]

  schema = [
    {
      key: 'name',
      label: 'Client Name',
      rules: [{
        required: true,
        message: 'Client name is required',
        trigger: 'blur'
      }]
    },
    {
      key: 'code',
      label: 'Client Code',
      placeholder: 'Enter client code',
      rules: [{
        required: true,
        message: 'Client code is required',
        trigger: 'blur'
      }]
      // class: 'w-50'
    },
    {
      key: 'contactName',
      rules: [{
        required: true,
        message: 'Contact name is required',
        trigger: 'blur'
      }],
      class: 'w-50 clearfix'
    },
    {
      key: 'contactTitle',
      rules: [{
        required: true,
        message: 'Contact title is required',
        trigger: 'blur'
      }],
      class: 'w-50'
    },
    {
      key: 'sector',
      type: 'select',
      items: [
        { id: 'Charity', name: 'Charity' },
        { id: 'Retail Goods & Services', name: 'Retail Goods & Services' },
        { id: 'Energy, Resources & Materials', name: 'Energy, Resources & Materials' },
        { id: 'Financials', name: 'Financials' },
        { id: 'Food & Drink', name: 'Food & Drink' },
        { id: 'Public Sector', name: 'Public Sector' },
        { id: 'Health Care', name: 'Health Care' },
        { id: 'Industrial & Manufacturing', name: 'Industrial & Manufacturing' },
        { id: 'Professional Services', name: 'Professional Services' },
        { id: 'Technology, Media & Telecoms', name: 'Technology, Media & Telecoms' }
      ],
      rules: [{
        required: true,
        message: 'Sector is required',
        trigger: 'blur'
      }],
      class: 'w-50 clearfix'
    },
    {
      key: 'industry',
      type: 'select',
      items: [],
      rules: [{
        required: true,
        message: 'Industry is required',
        trigger: 'blur'
      }],
      class: 'w-50'
    },
    {
      key: 'officeLocation',
      type: 'select',
      items: [
        { id: 'Africa', name: 'Africa' },
        { id: 'Asia', name: 'Asia' },
        { id: 'Australia & NZ', name: 'Australia & NZ' },
        { id: 'Belgium', name: 'Belgium' },
        { id: 'Canada', name: 'Canada' },
        { id: 'Europe Other', name: 'Europe Other' },
        { id: 'France', name: 'France' },
        { id: 'Germany', name: 'Germany' },
        { id: 'Italy', name: 'Italy' },
        { id: 'Middle East', name: 'Middle East' },
        { id: 'Netherlands', name: 'Netherlands' },
        { id: 'North America', name: 'North America' },
        { id: 'South Africa', name: 'South Africa' },
        { id: 'South America', name: 'South America' },
        { id: 'Spain', name: 'Spain' },
        { id: 'United Kingdom', name: 'United Kingdom' }
      ],
      rules: [{
        required: true,
        message: 'Office location is required',
        trigger: 'blur'
      }],
      class: 'w-50 clearfix'
    },
    {
      key: 'numberOfEmployees',
      label: 'No. Employees',
      type: 'select',
      items: [
        { id: '< 100', name: '< 100' },
        { id: '101 - 500', name: '101 - 500' },
        { id: '501 - 1,000', name: '501 - 1,000' },
        { id: '1,001 - 5,000', name: '1,001 - 5,000' },
        { id: '5,001 - 10,000', name: '5,001 - 10,000' },
        { id: '> 10,000', name: '> 10,000' }
      ],
      rules: [{
        required: true,
        message: 'No. employees is required',
        trigger: 'blur'
      }],
      class: 'w-50'
    },
    {
      key: 'annualTurnover',
      type: 'currency',
      rules: [{
        required: true,
        message: 'Annual turnover is required',
        trigger: 'blur'
      }]
    }
  ]

  designSchema = [
    {
      key: 'logo',
      label: 'Logo',
      type: 'file',
      accept: 'image/*'
      // rules: [{
      //  required: true,
      //  message: 'Logo is required'
      // }]
    },
    {
      label: 'Dashboard Design',
      type: 'heading'
    },
    {
      key: 'dashboardDesign.logoColor',
      label: 'Coode Logo',
      type: 'select',
      placeholder: 'Select logo color',
      items: [{
        id: 'white',
        name: 'White'
      }, {
        id: 'blue',
        name: 'Blue'
      }],
      class: 'w-50'
    },
    {
      key: 'dashboardDesign.backgroundColor',
      label: 'Background Color',
      type: 'color',
      // rules: [{
      //  required: true,
      //  message: 'Background color is required'
      // }],
      class: 'w-33 clear-both'
    },
    {
      key: 'dashboardDesign.buttonColor',
      label: 'Button Color',
      type: 'color',
      // rules: [{
      //  required: true,
      //  message: 'Button color is required'
      // }],
      class: 'w-33'
    },
    {
      label: 'Survey Design',
      type: 'heading'
    },
    {
      key: 'surveyDesign.backgroundColor',
      label: 'Background Color',
      type: 'color',
      // rules: [{
      //  required: true,
      //  message: 'Background color is required'
      // }],
      class: 'w-33'
    },
    {
      key: 'surveyDesign.buttonColor',
      label: 'Button / Progress Bar Color',
      type: 'color',
      // rules: [{
      //  required: true,
      //  message: 'Button color is required'
      // }],
      class: 'w-33'
    },
    {
      key: 'surveyDesign.lineColor',
      label: 'Line Color',
      type: 'color',
      // rules: [{
      //  required: true,
      //  message: 'Line color is required'
      // }],
      class: 'w-33'
    }
  ]
}
